<template>
	<div class="select-base-info">
		<CardTemplate>
			<template #title>[{{ lineInfo.lineName || "" }}]基础信息</template>
			<div class="data-list">
				<div class="data-item">
					<img src="@/assets/bigScreen/icon_right_01.png" alt="" />
					<div class="des">
						<p class="title">车辆总数</p>
						<p class="value">
							<span class="number">{{ baseInfo.vehicleSum || 0 }}</span>
							<span>台</span>
						</p>
					</div>
				</div>
				<div class="data-item">
					<img src="@/assets/bigScreen/icon_right_02.png" alt="" />
					<div class="des">
						<p class="title">驾驶员数量</p>
						<p class="value">
							<span class="number">{{ baseInfo.driverSum || 0 }}</span>
							<span>个</span>
						</p>
					</div>
				</div>
				<div class="data-item">
					<img src="@/assets/bigScreen/icon_right_03.png" alt="" />
					<div class="des">
						<p class="title">站点数量</p>
						<p class="value">
							<span class="number">{{ baseInfo.stationSum || 0 }}</span>
							<span>个</span>
						</p>
					</div>
				</div>
				<div class="data-item">
					<img src="@/assets/bigScreen/icon_right_04.png" alt="" />
					<div class="des">
						<p class="title">线网长度</p>
						<p class="value">
							<span class="number">{{ baseInfo.lineNetSum || 0 }}</span>
							<span>km</span>
						</p>
					</div>
				</div>
			</div>
			<!-- <div class="info-content"> -->
			<!-- <SelectWorkInfo :lineInfo="lineInfo"></SelectWorkInfo> -->
			<!-- <SelectMileageAnalyze :lineInfo="lineInfo"></SelectMileageAnalyze>
				<SelectFlowStatistic :lineInfo="lineInfo"></SelectFlowStatistic> -->
			<!-- </div> -->
		</CardTemplate>
	</div>
</template>

<script>
import CardTemplate from "../CardTemplate/index.vue";
// import SelectWorkInfo from "../SelectWorkInfo/index.vue";
// import SelectMileageAnalyze from "../SelectMileageAnalyze/index.vue";
// import SelectFlowStatistic from "../SelectFlowStatistic/index.vue";
import { mapState } from "vuex";
import { selectBaseInfoMock } from "../../mock";

export default {
	components: {
		CardTemplate
		// SelectWorkInfo
		// SelectMileageAnalyze,
		// SelectFlowStatistic
	},
	data() {
		return {
			baseInfo: {}
		};
	},
	props: {
		lineInfo: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		...mapState(["isMockData"])
	},
	/* created() {
		this.init();
	}, */
	watch: {
		/* isMockData() {
			this.isMockData && this.init();
		}, */
		lineInfo: {
			handler(obj) {
				obj.routeId && this.init();
			},
			deep: true
		}
	},
	methods: {
		init() {
			if (this.isMockData) {
				this.baseInfo = selectBaseInfoMock || {};
				return;
			}
			const url = `/crrc-palm-bus/gis/line/baseInfo`;
			const params = {
				month: "",
				routeId: this.lineInfo.routeId,
				year: ""
			};
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				this.baseInfo = res || {};
			});
		}
	}
};
</script>

<style lang="scss">
.select-base-info {
	.card-container .card-top .tilte-text {
		margin-top: -4px;
		margin-left: 12px;
		font-size: 21px;
	}

	.card-container .card-content .inner {
		// background: none;
		background: linear-gradient(180deg, #07375d 0%, #00213a 100%);
		border-radius: 0 0 12px 12px;
	}

	.data-list {
		display: flex;
		justify-content: space-around;
		padding-bottom: 16px;
		width: 100%;
		flex-wrap: wrap;
		padding: 24px 0 45px;

		.data-item {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 16px;
			width: 50%;

			img {
				width: 65px;
				height: 64px;
			}

			.des {
				margin-left: 16px;

				.title {
					font-size: 16px;
					color: #fff;
				}

				.value {
					font-size: 14px;
					color: #00fff1;

					.number {
						font-size: 24px;
						font-weight: 600;
					}
				}
			}
		}
	}

	.info-content {
		padding: 0 14px 14px;
		width: 100%;

		/* .card-container-two {
			margin-bottom: 20px;
		} */
	}
}
</style>
