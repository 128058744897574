<template>
	<div class="car-monitor-content">
		<CardTemplate>
			<template #title>车辆监控视频</template>
			<div class="data-list" v-if="dataList.length">
				<div class="data-item" v-for="(item, index) in dataList" :key="index">
					<div class="title-content">
						<span class="icon"></span>
						<span class="text">监控视频</span>
						<span class="text ve-no">{{ item.vehicleNo }}</span>
					</div>
					<div class="vedio-content">
						<template v-if="showVedioList[item.vehicleNo]">
							<video
								class="s-v"
								:src="showVedioList[item.vehicleNo][index]"
								width="100%"
								height="160"
								controls
								autoplay
								controlslist="nodownload noremoteplayback noplaybackrate foobar"
								disablepictureinpicture
							>
								<!-- <source :src="showVedioList[item.vehicleNo][index]" type="video/mp4" /> -->
							</video>
						</template>
						<template v-else>
							<video-player
								:stream="false"
								:channel="false"
								:autoPlay="true"
								:fromPage="`line`"
								:hasControl="true"
								:ref="`vplayer${index}`"
								class="vedio-item"
								:params="getParams(index)"
							></video-player>
						</template>
						<!-- <video :id="`myVedio${index}`" class="vedio-item"></video>
						<i class="iconfont icon-kaishi play-icon" v-if="!isPlay || showIcon" @click="playHandle"></i>
						<i class="iconfont icon-zanting play-icon" v-if="isPlay && showIcon" @click="playHandle"></i> -->
					</div>
					<!-- <div class="des">{{ item.stationName }}出站 {{ item.goType }}</div>
					<div class="time">{{ item.time }}</div> -->
				</div>
			</div>
		</CardTemplate>
	</div>
</template>

<script>
import CardTemplate from "../CardTemplate/index.vue";
import VideoPlayer from "@/components/pages/admin/videoPlayer/flvPlayer";
// import Flv from "mpegts.js";

export default {
	components: {
		CardTemplate,
		VideoPlayer
	},
	data() {
		return {
			car: {},
			dataList: [],
			showVedioList: {
				临233367: {
					0: "https://official-website-zhiyu.eos-zhuzhou-1.cmecloud.cn/%2Ftlf/schedule/video/202%E8%B7%AF233367%E9%80%9A%E9%81%931%E5%8F%B8%E6%9C%BA.mp4?versionId=null&response-content-disposition=attachment&AWSAccessKeyId=6B7PEBWZPB36C5J91P3K&Expires=1752912642&Signature=2tTWJJb6rBHOb%2BMmUb4nKlSNWdM%3D",
					1: "https://official-website-zhiyu.eos-zhuzhou-1.cmecloud.cn/%2Ftlf/schedule/video/202%E8%B7%AF233367%E9%80%9A%E9%81%932%E9%81%93%E8%B7%AF.mp4?versionId=null&response-content-disposition=attachment&AWSAccessKeyId=6B7PEBWZPB36C5J91P3K&Expires=1752912665&Signature=h8XjN4KVca40vVpMOQAdq5awVlo%3D"
				},
				临233377: {
					0: "https://official-website-zhiyu.eos-zhuzhou-1.cmecloud.cn/%2Ftlf/schedule/video/202%E8%B7%AF233377%E9%80%9A%E9%81%931%E5%8F%B8%E6%9C%BA.mp4?versionId=null&response-content-disposition=attachment&AWSAccessKeyId=6B7PEBWZPB36C5J91P3K&Expires=1752912685&Signature=Av%2FRqp%2FC1yo3dYYXi71utJrhNyg%3D",
					1: "https://official-website-zhiyu.eos-zhuzhou-1.cmecloud.cn/%2Ftlf/schedule/video/202%E8%B7%AF233377%E9%80%9A%E9%81%932%E9%81%93%E8%B7%AF.mp4?versionId=null&response-content-disposition=attachment&AWSAccessKeyId=6B7PEBWZPB36C5J91P3K&Expires=1752912710&Signature=%2F1iWhfYlyWYLIqqeyIuKKZfjbxQ%3D"
				},
				临233363: {
					0: "https://official-website-zhiyu.eos-zhuzhou-1.cmecloud.cn/%2Ftlf/schedule/video/208%E8%B7%AF233363%E9%80%9A%E9%81%931%E5%8F%B8%E6%9C%BA.mp4?versionId=null&response-content-disposition=attachment&AWSAccessKeyId=6B7PEBWZPB36C5J91P3K&Expires=1752912727&Signature=JaqT7QV2zKr53v0iVRBzcP9i3eI%3D",
					1: "https://official-website-zhiyu.eos-zhuzhou-1.cmecloud.cn/%2Ftlf/schedule/video/208%E8%B7%AF233363%E9%80%9A%E9%81%932%E9%81%93%E8%B7%AF.mp4?versionId=null&response-content-disposition=attachment&AWSAccessKeyId=6B7PEBWZPB36C5J91P3K&Expires=1752912746&Signature=azmBrcKTImLJx2bksOznFDL5xZI%3D"
				},
				临233383: {
					0: "https://official-website-zhiyu.eos-zhuzhou-1.cmecloud.cn/%2Ftlf/schedule/video/208%E8%B7%AF233383%E9%80%9A%E9%81%931%E5%8F%B8%E6%9C%BA.mp4?versionId=null&response-content-disposition=attachment&AWSAccessKeyId=6B7PEBWZPB36C5J91P3K&Expires=1752912847&Signature=ho5agNTFcJrGT%2BVV7kxEdIhE49Y%3D",
					1: "https://official-website-zhiyu.eos-zhuzhou-1.cmecloud.cn/%2Ftlf/schedule/video/208%E8%B7%AF233383%E9%80%9A%E9%81%932%E9%81%93%E8%B7%AF.mp4?versionId=null&response-content-disposition=attachment&AWSAccessKeyId=6B7PEBWZPB36C5J91P3K&Expires=1752912869&Signature=TAEuVlUc8z33GDwS9swpOrWbRIU%3D"
				}
			}
		};
	},
	props: {
		lineInfo: {
			type: Object,
			default: () => ({})
		},
		carInfo: {
			type: Object,
			default: () => ({})
		}
	},

	watch: {
		lineInfo: {
			handler() {
				this.dataList = [];
				this.getCarList();
			}
		},
		carInfo(obj) {
			this.dataList = [];
			this.car = obj;
			// console.log(this.car, "carInfo");
			setTimeout(() => {
				this.init();
			});
		}
	},

	computed: {
		getParams() {
			return index => {
				const channelId = index === 0 ? 4 : 2;
				return {
					clientId: this.car.clientId,
					channelId
				};
			};
		}
	},
	methods: {
		getCarList() {
			const url = "/crrc-palm-bus/gis/line/realtime";
			const params = { routeId: this.lineInfo.routeId };
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				const list = res || [];
				this.car = list[0] || {};
				this.init();
			});
		},
		init() {
			this.dataList = [this.car, this.car];
		}
	}
};
</script>

<style lang="scss">
.car-monitor-content {
	margin-top: 10px;
	.card-container .card-top .tilte-text {
		margin-top: -4px;
		margin-left: 12px;
		font-size: 21px;
	}

	.card-container .card-content .inner {
		// background: none;
		background: linear-gradient(180deg, #07375d 0%, #00213a 100%);
		border-radius: 0 0 12px 12px;
	}

	.data-list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: 30px 0;
		.data-item {
			width: 202px;
			height: 215px;
			background: url("~@/assets/bigScreen/line/vedio_bg.png") no-repeat;
			background-size: 100% 100%;
			padding: 10px 18px 20px 10px;
			.title-content {
				display: flex;
				align-items: center;
				.icon {
					width: 20px;
					height: 18px;
					background: url("~@/assets/bigScreen/line/vedio_icon.png") no-repeat;
					background-size: 100% 100%;
					margin-right: 7px;
				}
				.text {
					font-size: 11px;
					font-weight: 600;
					color: #ffffff;
					line-height: 16px;
				}
				.ve-no {
					margin-left: auto;
				}
			}
			.vedio-content {
				position: relative;
				height: 160px;
				margin: 7px auto 14px;
				.vedio-item {
					width: 100%;
					height: 100%;
					min-height: 0 !important;
				}
				.play-icon {
					position: absolute;
					left: 50%;
					top: 50%;
					cursor: pointer;
				}
				.s-v {
					object-fit: fill;
					&::-webkit-media-controls-timeline,
					&::-webkit-media-controls-mute-button,
					&::-webkit-media-controls-volume-control-container,
					&::-webkit-media-controls-volume-slider,
					&::-webkit-media-controls-toggle-closed-captions-button {
						display: none;
					}
				}
			}
			.des {
				font-size: 11px;
				font-weight: 600;
				color: #ffffff;
				line-height: 16px;
				margin-bottom: 4px;
				padding-left: 6px;
			}
			.time {
				font-size: 12px;
				color: #999999;
				line-height: 17px;
				padding-left: 6px;
			}
		}
	}
}
</style>
