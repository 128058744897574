<template>
	<div class="line-move-page">
		<div class="content-area">
			<!-- <div class="left-content">
				<slot name="left"></slot>
			</div> -->
			<div class="center-content flex">
				<div class="line-map flex">
					<!-- <div class="map-bg" :style="mapBgStyle"></div> -->
					<div class="up-list flex" id="upList" v-show="upStationList.length">
						<div class="left-top">
							<div class="img"></div>
							<div class="text">
								<p>上</p>
								<p>行</p>
							</div>
						</div>
						<div class="right-top">
							<div class="img"></div>
						</div>
						<div class="up-item flex" v-for="(item, index) in upStationList" :key="index">
							<div class="left flex">
								<p class="station-index">{{ index + 1 }}</p>
								<p class="station-name" :title="item.siteName">{{ item.siteName }}</p>
							</div>
							<div class="right" v-if="index !== upStationList.length - 1">
								<template v-if="item.isCurrent">
									<template v-if="isLargeCarIcon">
										<span class="ve-no">{{ item.vehicleNo }}</span>
										<span class="car-icon" :class="`car-icon-${item.carState}`"></span>
									</template>
									<template v-else>
										<span class="small-icon"></span>
									</template>
								</template>
								<p class="line" :class="`line-c-${item.traffic}`" :style="getStyle(upStationList)"></p>
							</div>
						</div>
					</div>
					<div class="down-list flex" v-show="downStationList.length">
						<div class="left-bottom">
							<div class="img"></div>
						</div>
						<div class="right-bottom">
							<div class="text">
								<p>下</p>
								<p>行</p>
							</div>
							<div class="img"></div>
						</div>
						<div class="up-item flex" v-for="(item, index) in downStationList" :key="index">
							<div class="left flex" @click="openStationInfo({ ...item, routeId: lineInfo.routeId })">
								<span class="station-name" :title="item.siteName">{{ item.siteName }}</span>
								<span class="station-index blue">{{ index + 1 }}</span>
							</div>
							<div class="right" v-if="index !== 0">
								<template v-if="item.isCurrent">
									<template v-if="isLargeCarIcon">
										<span class="ve-no">{{ item.vehicleNo }}</span>
										<span class="car-icon" :class="`car-icon-${item.carState}`" @click="openCarInfo(item)"></span>
									</template>
									<template v-else>
										<span class="small-icon" @click="openCarInfo(item)"></span>
									</template>
								</template>
								<p class="line" :class="`line-c-${item.traffic}`" :style="getStyle(downStationList)"></p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="right-content">
				<slot name="right"></slot>
			</div> -->
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isLargeCarIcon: true,
			isSign: true,
			upStationList: [],
			downStationList: [],
			lineData: {},
			mapBgStyle: {},
			passageData: {}
		};
	},
	props: {
		lineInfo: {
			type: Object,
			default: () => ({})
		}
	},
	watch: {
		lineInfo: {
			handler(obj) {
				console.log(obj, "----res");
				this.init();
			},
			immediate: true
		}
		/* "lineInfo.passageData": {
			handler(obj) {
				this.passageData = obj;
			}
		} */
	},
	/* activated() {
		this.lineInfo.routeId && this.init();
	},
	deactivated() {
		this.clearInter();
	}, */
	methods: {
		init() {
			this.lineData = JSON.parse(JSON.stringify(this.lineInfo));
			const { downSites, upSites, vehicles } = this.lineData;
			this.upStationList = upSites || [];
			this.downStationList = downSites || [];
			this.formatList(vehicles);

			/* this.clearInter();
			const url = `/crrc-palm-bus/realtimeOperation/getRouteDetails`;
			const params = { routeId: this.lineInfo.routeId };
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				const { downSites, upSites, vehicles } = res;
				this.lineData = res || {};
				this.upStationList = upSites || [];
				this.downStationList = downSites || [];
				this.formatList(vehicles);
				// this.getMapBgStyle();
				this.setInter();
			}); */
		},
		formatList(list) {
			/* traffic 路况：0：未知路况、1：畅通、2：缓行、3：拥堵、4：严重拥堵 */
			this.upStationList.forEach((item, index, arr) => {
				// 后端线路两站之间拥堵情况在下一站体现，上行站前端在当前站体现
				if (index < arr.length - 1) {
					item.traffic = arr[index + 1].traffic;
				}
				// 匹配车辆
				const target = list.find(i => i.psiteId == item.siteId);
				if (target) {
					// item = { ...item, isCurrent: true, vehicleNo: target.vehicleNo, vehicleId: target.vehicleId };
					this.$set(item, "isCurrent", true);
					this.$set(item, "vehicleNo", target.vehicleNo);
					this.$set(item, "vehicleId", target.vehicleId);
					this.$set(item, "carState", target.state);
				} else {
					this.$set(item, "isCurrent", false);
				}
			});
			this.downStationList.forEach(item => {
				// 后端线路两站之间拥堵情况在下一站体现
				// 布局原因  下行站体现与后端一致
				/* if (index < arr.length - 1) {
					item.traffic = arr[index + 1].traffic || 0;
				} */
				// 匹配车辆
				const target = list.find(i => i.psiteId == item.siteId);
				if (target) {
					this.$set(item, "isCurrent", true);
					this.$set(item, "vehicleNo", target.vehicleNo);
					this.$set(item, "vehicleId", target.vehicleId);
					this.$set(item, "carState", target.state);
				} else {
					this.$set(item, "isCurrent", false);
				}
			});
		},
		setInter() {
			this.clearInter();
			this.timer = setInterval(() => {
				this.init();
			}, 5000);
		},
		clearInter() {
			this.timer && clearInterval(this.timer);
			this.timer = null;
		},
		getStyle(list) {
			const exitWidth = 1500 - 20 * list.length;
			const res = exitWidth / (list.length - 1);
			return {
				width: `${res}px`
			};
		}
		/* openStationInfo(data) {
			this.$emit("showStationInfo", data);
		},
		openCarInfo(data) {
			this.$emit("showCarInfo", { ...data, routeId: this.lineData.routeId });
		} */
	}
};
</script>

<style lang="scss" scoped>
.line-move-page {
	width: 100%;
	padding: 0 10px;
	.flex {
		display: flex;
	}
	.content-area {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-top: 20px;
		.left-content,
		.right-content {
			width: 340px;
		}
		.center-content {
			// width: 1102px;
			flex: 1;
			padding: 20px 0;
			flex-direction: column;
			align-items: center;
			// margin: 0 100px;
			.line-map {
				position: relative;
				flex-direction: column;
				align-items: center;
				.map-bg {
					position: absolute;
					left: -70px;
					border: 6px solid #233c73;
				}
				.up-list,
				.down-list {
					position: relative;
					align-items: flex-start;
					.left-top,
					.left-bottom,
					.right-top,
					.right-bottom {
						position: absolute;
						.img {
							width: 44px;
							height: 26px;
							background: url("~@/assets/homeNew/index/arrow_icon.png") no-repeat;
							background-size: 100% 100%;
						}
					}
					.left-top {
						left: -56px;
						top: -4px;
						.img {
							margin-left: 9px;
						}
						.text {
							display: flex;
							flex-direction: column;
							justify-content: center;
							width: 24px;
							height: 48px;
							background: #2b8a23;
							border: 1px solid #979797;
							color: #fff;
							text-align: center;
							line-height: 20px;
						}
					}
					.right-top {
						top: 8px;
						right: -60px;
						.img {
							transform: rotate(90deg);
						}
					}
					.left-bottom {
						left: -56px;
						bottom: 14px;
						.img {
							transform: rotate(-90deg);
						}
					}
					.right-bottom {
						bottom: 4px;
						right: -56px;
						.img {
							transform: rotate(180deg);
						}
						.text {
							display: flex;
							flex-direction: column;
							justify-content: center;
							width: 24px;
							height: 48px;
							background: #0283eb;
							border: 1px solid #979797;
							color: #fff;
							text-align: center;
							line-height: 20px;
							margin-left: 28px;
						}
					}
					.up-item {
						align-items: flex-start;
						z-index: 9;
						.left {
							flex-direction: column;
							align-items: center;
							.station-index {
								width: 20px;
								height: 20px;
								background: #2c8a24;
								border: 2px solid #ffffff;
								border-radius: 50%;
								font-size: 10px;
								color: #ffffff;
								line-height: 18px;
								text-align: center;
								margin-top: -7px;
								cursor: pointer;
							}
							.station-name {
								font-size: 12px;
								color: #ffffff;
								line-height: 16px;
								writing-mode: vertical-lr;
								text-orientation: upright;
								margin-top: 2px;
								cursor: pointer;
								max-height: 75px;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
								// flex: 1;
								/* max-width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap; */
							}
						}
						.right {
							position: relative;
							flex: 1;
							.line {
								height: 3px;
								background: #2b8a23;
								&.line-c-4 {
									background: #ff3141;
								}
								&.line-c-3 {
									background: #ff6430;
								}
								&.line-c-2 {
									background: #fea931;
								}
								&.line-c-0 {
									background: #ccc;
								}
							}
							.car-icon {
								position: absolute;
								left: 50%;
								top: -14px;
								margin-left: -14px;
								width: 28px;
								height: 15px;
								background-image: url("~@/assets/homeNew/index/normal_car_icon.png");
								background-repeat: no-repeat;
								background-size: 100% 100%;
								cursor: pointer;
								&.car-icon-1 {
									background-image: url("~@/assets/homeNew/index/normal_car_icon.png");
								}
								&.car-icon-2 {
									background-image: url("~@/assets/homeNew/index/error_car_icon.png");
								}
								&.car-icon-3 {
									background-image: url("~@/assets/homeNew/index/alram_car_icon.png");
								}
								&.car-icon-4 {
									background-image: url("~@/assets/homeNew/index/outline_car_icon.png");
								}
							}
							.ve-no {
								position: absolute;
								left: 50%;
								top: -40px;
								margin-left: -17px;
								width: 60px;
								height: 26px;
								background: url("~@/assets/homeNew/index/ve_bg_icon.png") no-repeat;
								background-size: 100% 100%;
								font-size: 10px;
								// font-weight: 800;
								color: #000000;
								line-height: 18px;
								text-align: center;
							}
							.small-icon {
								position: absolute;
								left: 50%;
								top: -18px;
								margin-left: -6px;
								width: 13px;
								height: 16px;
								background: url("~@/assets/homeNew/index/mark_icon.png") no-repeat;
								background-size: 100% 100%;
								cursor: pointer;
							}
						}
					}
				}
				.down-list {
					flex-direction: row-reverse;
					align-items: flex-end;
					margin-top: 10px;
					.up-item {
						align-items: flex-end;
						.left {
							.station-name {
								margin-top: 0;
							}

							.station-index {
								background: #0582eb;
								margin-top: 2px;
							}
						}
						.right {
							margin-bottom: 8px;
							.line {
								background: #0582eb;
							}
							/* .car-icon {
								// top: -22px;
								// margin-left: -27px;
								background: url("~@/assets/homeNew/index/normal_car_icon.png") no-repeat;
								background-size: 100% 100%;
							} */
						}
					}
				}
			}
			.center-bottom {
				margin-top: 30px;
				align-items: center;
				padding: 8px 22px;
				background: rgba(35, 60, 115, 0.4);
				border-radius: 4px;
				.mark-item {
					display: flex;
					align-items: center;
					margin-right: 30px;
					&:last-child {
						margin-right: 0;
					}
					.icon {
						margin-right: 7px;
					}
					.text {
						font-size: 12px;
						color: #ffffff;
						line-height: 18px;
					}
				}
				.normal {
					.icon {
						width: 28px;
						height: 15px;
						background: url("~@/assets/homeNew/index/normal_car_icon.png") no-repeat;
						background-size: 100% 100%;
					}
				}
				.spd {
					.icon {
						width: 28px;
						height: 15px;
						background: url("~@/assets/homeNew/index/error_car_icon.png") no-repeat;
						background-size: 100% 100%;
					}
				}
				.out {
					.icon {
						width: 28px;
						height: 15px;
						background: url("~@/assets/homeNew/index/alram_car_icon.png") no-repeat;
						background-size: 100% 100%;
					}
				}
				.out-line {
					.icon {
						width: 28px;
						height: 15px;
						background: url("~@/assets/homeNew/index/outline_car_icon.png") no-repeat;
						background-size: 100% 100%;
					}
				}
				.jam-1 {
					.icon {
						width: 19px;
						height: 3px;
						background: #ff3141;
						border-radius: 2px;
					}
				}
				.jam-2 {
					.icon {
						width: 19px;
						height: 3px;
						background: #ff6430;
						border-radius: 2px;
					}
				}
				.jam-3 {
					.icon {
						width: 19px;
						height: 3px;
						background: #fea931;
						border-radius: 2px;
					}
				}
				.jam-4 {
					.icon {
						width: 19px;
						height: 3px;
						background: #64c15c;
						border-radius: 2px;
					}
				}
				.jam-5 {
					.icon {
						width: 19px;
						height: 3px;
						background: #cccccc;
						border-radius: 2px;
					}
				}
				.jam-6 {
					.icon {
						align-items: center;
						justify-content: center;
						width: 19px;
						height: 3px;
						background: #2b8a23;
						border-radius: 2px;
						.circle {
							width: 7px;
							height: 7px;
							border-radius: 50%;
							background: #2b8a23;
						}
					}
				}
				.jam-7 {
					.icon {
						align-items: center;
						justify-content: center;
						width: 19px;
						height: 3px;
						background: #0283eb;
						border-radius: 2px;
						.circle {
							width: 7px;
							height: 7px;
							border-radius: 50%;
							background: #0283eb;
						}
					}
				}
			}
		}
	}
}
</style>
