<template>
	<div class="line-list">
		<CardTemplate>
			<template #title>线路列表</template>
			<div class="data-content">
				<!-- <el-select class="select" v-model="selectLine" filterable @change="changeSelect">
					<el-option
						v-for="item in dataList"
						:key="item.routeId"
						:label="`${item.lineName}(${item.startPoint}-${item.endPoint})`"
						:value="item.routeId"
					></el-option>
				</el-select> -->
				<div class="data-list" :class="isAll ? 'is-all' : ''">
					<div
						class="data-item"
						v-for="(item, index) in dataList"
						:key="index"
						:class="item.isActive ? 'active' : ''"
						@click="changeHandle(item, index)"
					>
						<div class="icon"></div>
						<div class="des">
							<p class="line">{{ `${item.lineName}(${item.startPoint}-${item.endPoint})` }}</p>
							<p class="info">配车数 : {{ item.vehicleNum || 0 }} / 站点数 : {{ item.siteNum }}</p>
						</div>
					</div>
				</div>
			</div>
		</CardTemplate>
	</div>
</template>

<script>
import CardTemplate from "../CardTemplate/index.vue";
import { mapState } from "vuex";
import { lineInfoListMock } from "../../mock";

export default {
	components: {
		CardTemplate
	},
	data() {
		return {
			selectLine: "",
			dataList: []
		};
	},
	props: {
		isAll: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapState(["isMockData"])
	},
	watch: {
		isMockData() {
			this.init();
		}
	},
	// created() {
	// 	this.init();
	// },
	activated() {
		this.init();
	},
	methods: {
		async init() {
			if (this.isMockData) {
				this.dataList = lineInfoListMock || [];
				this.formatData();
				return;
			}
			const url = `/crrc-palm-bus/gis/line/lineInfo`;
			const params = {};
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				this.dataList = res || [];
				this.formatData();
			});
		},
		formatData() {
			this.dataList = this.dataList.map(item => {
				return {
					...item,
					isActive: false
				};
			});
			this.selectLine = this.dataList[0].routeId;
			this.dataList[0].isActive = true;
			this.$emit("get-select-line", this.dataList[0]);
		},
		changeSelect(val) {
			const target = this.dataList.find(item => item.routeId === val);
			const index = this.dataList.findIndex(item => item.routeId === val);
			this.changeHandle(target, index);
		},
		changeHandle(obj, dex) {
			this.$emit("get-select-line", obj);
			this.selectLine = obj.routeId;
			this.dataList.forEach((item, index) => {
				item.isActive = dex === index;
			});
		}
	}
};
</script>

<style lang="scss">
.line-list {
	.card-container .card-content .inner {
		border-radius: 0 0 12px 12px;
		background: linear-gradient(180deg, #07375d 0%, #00213a 100%);
	}

	.data-content {
		position: relative;
		z-index: 99;
		padding: 10px 25px;

		.select {
			margin-bottom: 12px;
			width: 100%;
			height: 36px;

			.el-input__inner {
				// padding: 10px;
				color: #d9e7ff;
				background-color: transparent;
				border: 1px solid #a0a9b8;

				&::placeholder {
					color: #d9e7ff;
				}
			}

			.el-input .el-select__caret {
				color: #d9e7ff;
			}
		}

		.data-list {
			width: 100%;
			max-height: 564px;
			overflow: auto;
			&::-webkit-scrollbar {
				width: 4px;
			}

			&::-webkit-scrollbar-track {
				background-color: rgba(108, 128, 151, 0.15);
				border-radius: 2px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 2px;
				background-color: rgba(54, 169, 228, 0.4);
			}
			&.is-all {
				max-height: 564px;
			}
			.data-item {
				position: relative;
				display: flex;
				align-items: center;
				margin-bottom: 12px;
				height: 60px;
				// background-image: url("~@/assets/bigScreen/line_list_bg.png");
				// background-repeat: no-repeat;
				// background-size: 100% 100%;
				border: 1px solid rgba(108, 128, 151, 0.2);
				border-radius: 6px 0 0 6px;
				cursor: pointer;

				&:last-child {
					margin-bottom: 0;
				}
				&::before {
					position: absolute;
					top: 0;
					left: 0;
					content: "";
					z-index: -1;
					width: 100%;
					height: 100%;
					background-image: url("~@/assets/bigScreen/line_list_bg.png");
					background-repeat: no-repeat;
					background-size: 100% 100%;
					opacity: 0.2;
				}

				.icon {
					width: 60px;
					height: 60px;
					background: url("~@/assets/bigScreen/no_select_car.png") no-repeat;
					background-size: 100% 100%;
				}

				.des {
					padding-top: 8px;
					margin-left: 15px;
					height: 60px;
					width: calc(100% - 75px);
					.line {
						margin-bottom: 3px;
						font-size: 16px;
						color: #fff;
						line-height: 22px;
						width: 100%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					.info {
						font-size: 12px;
						color: rgba(255, 255, 255, 0.8);
						line-height: 17px;
					}
				}

				&.active {
					background: url("~@/assets/bigScreen/selected.png") no-repeat;
					background-position: top right;
					background-color: #003e59;
					border: solid 1px #00f9ff;

					.icon {
						background: url("~@/assets/bigScreen/selected_car.png") no-repeat;
						background-size: 100% 100%;
					}

					.des {
						.line,
						.info {
							color: #00fff1;
						}
					}
				}
			}
		}
	}
}
</style>
