<template>
	<div class="map-wrap">
		<div class="top_bg"></div>
		<div id="baiduMap" class="map-box"></div>
		<div class="left-content">
			<LineList @get-select-line="getSelectLine" :isAll="isAll"></LineList>
		</div>
		<div class="right-content" v-show="!isAll">
			<SelectBaseInfo :lineInfo="lineInfo" v-show="!isAll"></SelectBaseInfo>
			<CarMonitor :lineInfo="lineInfo" :carInfo="minitorCarInfo"></CarMonitor>
		</div>
		<div class="bottom-content" v-show="!isAll">
			<LineBottom :lineInfo="lineInfo"></LineBottom>
		</div>
		<car-info ref="carInfo" />
		<div class="change-show back" @click="goBack">返回</div>
		<div class="change-show" @click="toggleAllHandle">{{ isAll ? "线路列表" : "全部线路" }}</div>
	</div>
</template>
<script>
import bMapStyleJson from "@/assets/json/bMapStyle";
import createMap from "@/utils/bMap";
import CustomPoint from "@/utils/customePoint";

import LineList from "../components/LineList/index";
import SelectBaseInfo from "../components/SelectBaseInfo/index";
import Station from "./station";
import CarMonitor from "../components/CarMonitor/index";
import LineBottom from "../components/LineBottom/index";
import CarInfo from "./components/carInfo.vue";

import { wsHeader } from "@/config/wsconfig";

export default {
	name: "",
	components: {
		LineList,
		SelectBaseInfo,
		CarMonitor,
		LineBottom,
		CarInfo
	},
	data() {
		return {
			BMap: null,
			lineInfo: {},
			carInfo: {},
			driving: null,
			isAll: false,
			selectData: {},
			routeList: [],
			minitorCarInfo: {},
			ws: null,
			wsTime: 0
		};
	},
	activated() {
		this.isAll = false;
		this.BMap = createMap("baiduMap", bMapStyleJson);
		this.BMap.addEventListener("moving", () => {
			// 更新窗口位置
			if (this.carInfo.vehicleId) {
				const { lng, lat } = this.carInfo;
				this.updatePanelPosition({ lng, lat });
			}
		});
		this.BMap.addEventListener("zoomend", () => {
			const overlays = this.BMap?.getOverlays();
			const zoomLevel = this.BMap?.getZoom();
			overlays.map(item => {
				if (item.stationConfig) {
					item.domElement.style.display = zoomLevel > 15 ? "" : "none";
				}
			});
			if (this.carInfo.vehicleId) {
				const { lng, lat } = this.carInfo;
				this.updatePanelPosition({ lng, lat });
			}
		});
	},
	deactivated() {
		this.$refs.carInfo?.closeDetailPanel();
		this.ws?.close();
		clearInterval(this.wsTime);
	},
	methods: {
		async getSelectLine(data) {
			const url = "/crrc-palm-bus/gis/line/lineMap";
			let params = {};
			// 关闭弹出的实时框
			this.$refs.carInfo?.closeDetailPanel();
			this.carInfo = {};
			if (data) {
				this.lineInfo = data || {};
				this.selectData = data;
				params = { routeId: data.routeId };
			} else {
				params = {};
			}
			const map = this.BMap;
			const res = await this.$http.post(url, params, { baseType: "base" });
			// 保存线路列表
			this.ws?.close();
			this.routeList = res || [];
			if (!this.isAll) {
				map?.clearOverlays();
			}
			this.openInter();
			const { allStationPoint } = this.drawLine(res, this.isAll && data ? "green" : "#048CFF");
			// 全部线路的模式下，先清除已经绘制的相同线路
			if (this.isAll) {
				const overlays = map?.getOverlays();
				const routeInfo = data || this.selectData;
				overlays.map(overlaysItem => {
					if (overlaysItem.routeId === routeInfo?.routeId) {
						overlaysItem.setStrokeColor("green");
					} else {
						overlaysItem.setStrokeColor("#048CFF");
					}
				});
			}

			if (!this.isAll) {
				map?.setViewport(allStationPoint);
			}
		},
		openInter() {
			if (this.isAll) {
				// 不传查所有车  不用遍历
				this.getCarInfo("");
			} else {
				this.getCarInfo(this.lineInfo.routeId);
			}
		},
		// 获取车辆信息
		async getCarInfo(routeId) {
			const url = "/crrc-palm-bus/gis/line/realtime";
			const res = await this.$http.post(url, { routeId }, { baseType: "base" });
			const vinList = res.map(item => {
				this.addBusPos(item);
				// 过滤到选中车辆;
				if (this.carInfo.vehicleId && this.carInfo.vehicleId === item.vehicleId) {
					const { lng, lat } = item;
					this.updatePanelPosition({ lng, lat });
				}
				return item.vin;
			});
			// 建立ws链接
			const ws = new WebSocket(`${wsHeader}//${location.host}/ischedule/vehicleMonitor/gb32960`);
			ws.onopen = () => {
				console.log("打开了ws链接，等待消息");
				ws.send(JSON.stringify(vinList));
				if (this.wsTime) {
					clearInterval(this.wsTime);
				}
				this.wsTime = setInterval(() => {
					// 心跳包
					this.ws.send(JSON.stringify(vinList));
				}, 1000 * 59);
				ws.onmessage = event => {
					console.log("接受消息推送");
					try {
						const carInfo = JSON.parse(event.data);
						const { data } = carInfo;
						const { vin } = data;
						const index = vinList.indexOf(vin);
						const car = res[index];
						if (car) {
							this.addBusPos({ ...car, lat: data.lat, lng: data.lng });
						}
					} catch (error) {
						console.log("非格式化数据");
					}
				};
			};
			this.ws = ws;
		},

		// 添加车辆位置标注
		addBusPos(posInfo) {
			const map = this.BMap;
			console.log(posInfo, "----车辆位置信息");
			const pcoord = gcoord.transform([posInfo.lng, posInfo.lat], gcoord.WGS84, gcoord.BD09);
			const bdPoint = new BMapGL.Point(pcoord[0], pcoord[1]);

			const marker = new CustomPoint(bdPoint, { txt: posInfo.vehicleNo, lineName: posInfo.routeName });
			marker.id = posInfo.vehicleId;
			const markers = map.getOverlays();
			for (let i = 0; i < markers.length; i++) {
				if (markers[i].id === posInfo.vehicleId) {
					map?.removeOverlay(markers[i]);
				}
			}
			map?.addOverlay(marker);
			if (marker.id === this.carInfo.vehicleId) {
				marker._div.classList.add("active");
			}
			marker.addEventListener("click", () => {
				this.carInfo = posInfo;
				this.minitorCarInfo = posInfo;
				// 当前点击的车放大1.2倍，其余正常大小
				const carList = Array.apply(null, document.getElementsByClassName("station_mark"));
				carList.forEach(item => {
					item.classList.remove("active");
				});
				marker._div.classList.add("active");
				// 打开车辆弹出框
				this.updatePanelPosition({ lng: posInfo.lng, lat: posInfo.lat });
				this.$refs.carInfo?.openDetailPanel(posInfo.vehicleId);
			});
		},
		// 绘制线路图
		drawLine(routeList, color) {
			if (routeList.length) {
				const map = this.BMap;
				const stationPointList = [];
				const allStationPoint = [];
				routeList.map(item => {
					const { siteVoList } = item;
					const drivingItem = new BMapGL.DrivingRoute(this.BMap);
					const siteList = siteVoList.map((site, idx) => {
						const p = gcoord.transform([site.longitude, site.latitude], gcoord.WGS84, gcoord.BD09);
						const station = new BMapGL.Point(p[0], p[1]);
						let type = "";
						if (idx === 0) {
							type = "first";
						} else if (idx === siteVoList.length - 1) {
							type = "last";
						}
						const stationMarker = new Station(station, { type, txt: site.siteName, index: idx });
						map?.addOverlay(stationMarker);
						stationPointList.push(site);
						allStationPoint.push(station);
						return station;
					});
					for (let i = 0; i < siteList.length; i++) {
						if (i !== siteList.length - 1) {
							drivingItem?.search(siteList[i], siteList[i + 1]); // 绘制两个点之间的路线
						}
					}
					drivingItem?.setSearchCompleteCallback(() => {
						const pts = drivingItem?.getResults().getPlan(0).getRoute(0).getPath();
						const polyline = new BMapGL.Polyline(pts, {
							strokeColor: color,
							strokeWeight: 5,
							strokeOpacity: 1
						});
						polyline.routeId = item.routeId;
						map?.addOverlay(polyline);
					});
				});
				return { stationPointList, allStationPoint };
			}
		},
		// 切换全部线路和单条线路
		toggleAllHandle() {
			this.isAll = !this.isAll;
			const obj = this.isAll ? null : this.selectData;
			this.getSelectLine(obj);
		},
		// 更新车辆数据panel位置
		updatePanelPosition(position) {
			const map = this.BMap;
			const { lng, lat } = position;
			const pcoord = gcoord.transform([lng, lat], gcoord.WGS84, gcoord.BD09);
			const bdPoint = new BMapGL.Point(pcoord[0], pcoord[1]);
			const pixelPosition = map?.pointToPixel(bdPoint);
			this.$refs.carInfo?.updatePos(pixelPosition);
		},
		goBack() {
			this.$router.push({
				name: "dashboard"
			});
		}
	}
};
</script>
<style lang="scss" scoped>
.map-wrap,
#baiduMap {
	position: relative;
	// z-index: 1;
	width: 100%;
	height: 100%;
}
.map-wrap {
	.top_bg {
		position: absolute;
		top: 0;
		z-index: 2;
		width: 100%;
		height: 165px;
		background: url("~@/assets/bigScreen/top_title_bg.png") no-repeat 0 0 transparent;
		background-size: 100% 100%;
	}
	.change-show {
		position: absolute;
		padding: 10px 15px;
		border: solid 1px #195dc4;
		border-radius: 5px;
		color: #fff;
		left: 205px;
		top: -50px;
		font-size: 14px;
		cursor: pointer;
		&.back {
			top: -120px;
			left: 50px;
		}
	}
}
.left-content {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1000;
	width: 428px;
}

.right-content {
	position: absolute;
	top: 0;
	right: 16px;
	z-index: 1000;
	width: 428px;
}
.bottom-content {
	position: absolute;
	bottom: 15px;
	left: 16px;
	width: calc(100% - 32px);
	height: 291px;
	z-index: 99;
}
::v-deep {
	.ra-wrapper {
		z-index: 9999;
	}
	.station_wrap {
		&:nth-child(2n + 1) {
			flex-direction: row-reverse;
			text-align: right;
		}
		.icon {
			width: 27px;
			height: 44px;
			background: url("./images/station@2x.png") no-repeat 0 0 / 100% 100% transparent;
			&.first {
				background: url("./images/start@2x.png") no-repeat 0 0 / 100% 100% transparent;
			}
			&.last {
				background: url("./images/end@2x.png") no-repeat 0 0 / 100% 100% transparent;
			}
			& + div {
				color: #ffffff;
				font-size: 14px;
				margin-left: 10px;
				margin-top: 4px;
				width: 100px;
				height: 22px;
				border-radius: 0 22px 22px 0;
			}
		}
	}
	.flex {
		display: flex;
	}
	.station_mark {
		color: #ffffff;
		z-index: 9;
		&:hover {
			.car_number {
				visibility: visible;
			}
		}
		.car_number {
			width: 136px;
			background: url("~@/assets/bigScreen/car_number.png") no-repeat 0 0 / 100% 100% transparent;
			font-size: 16px;
			padding: 15px;
			font-weight: 600;
			visibility: hidden;
			text-align: center;
		}
		&.active {
			.car_icon {
				transform: scale(1.3);
			}
		}
		.car_icon {
			width: 62px;
			height: 82px;
			background: url("~@/assets/bigScreen/car_back.png") no-repeat 0 0 /100% 100% transparent;
		}
	}
}
</style>
