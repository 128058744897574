<template>
	<div class="car-info-container" ref="panel">
		<div>
			<div class="car-info-line"></div>
			<div class="car-info-content">
				<div class="car-info-title">
					<span>{{ data.vehicleNo }}</span>
					<i class="el-icon-close close-icon" @click="closeDetailPanel"></i>
				</div>
				<div class="car-info-detail">
					<div class="car-info-time">
						<div class="gauge">
							<div>
								<div>{{ data.spd }}</div>
								km/h
							</div>
						</div>
						<ul>
							<li>时间：{{ data.dateTime }}</li>
							<li>里程：{{ data.mileage }}km</li>
							<li>电量：{{ data.soc }}</li>
							<li>地址：{{ data.siteNa }}</li>
						</ul>
					</div>
					<div class="car-info-route">
						<ul>
							<li>归属机构：{{ data.orgName }}</li>
							<li>起始站：{{ data.startSiteNa }}</li>
							<li>当前站：{{ data.siteNa }}</li>
							<li>载客数：{{ data.passengerNum }}</li>
						</ul>
						<ul>
							<li>线路：{{ data.routeName }}</li>
							<li>终点站：{{ data.endSiteNa }}</li>
							<li>下一站：{{ data.backSiteNa }}</li>
							<li></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "carInfo",
	data() {
		return { timeTag: 0, data: {} };
	},
	methods: {
		openDetailPanel(vehicleId) {
			// 存在定时器，则先清除
			if (this.timeTag) {
				clearInterval(this.timeTag);
			}
			this.getCarInfo(vehicleId);
			this.timeTag = setInterval(() => {
				this.getCarInfo(vehicleId);
			}, 5000);
			this.$refs.panel.classList.add("show");
		},
		closeDetailPanel() {
			this.$refs.panel.classList.remove("show");
			clearInterval(this.timeTag);
		},
		updatePos(pixelPosition) {
			this.$refs.panel.style = `left:${pixelPosition.x}px;top:${pixelPosition.y - 150}px`;
		},
		getCarInfo(vehicleId) {
			this.$http.post("/crrc-palm-bus/gis/line/getVehicleInfo", { vehicleId }, { baseType: "base" }).then(res => {
				console.log(res, "----车辆信息");
				this.data = res;
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.car-info-container {
	position: absolute;
	z-index: 1000;
	color: #ffffff;
	display: none;
	&.show {
		display: block;
	}
	& > div {
		position: relative;
		width: 100%;
	}
	.car-info-content {
		border: 1px solid #46738f;
		border-top: none;
		background: #042440;
		position: absolute;
		right: 0;
		top: 16px;
		width: 277px;
		font-size: 12px;
		.car-info-detail {
			.car-info-time {
				border-bottom: 1px solid #00b0ea;
				display: flex;
				align-items: center;
				ul {
					flex: 1;
					li {
						padding: 5px 0;
					}
				}
				.gauge {
					width: 105px;
					height: 123px;
					background: url("../images/gauge.png") no-repeat 0 0 / 100% 100% transparent;
					margin: 0 10px;
					display: flex;
					justify-content: center;
					align-items: center;
					text-align: center;
					& > div > div {
						margin-top: 20px;
						font-size: 22px;
						font-weight: 600;
					}
				}
			}
			.car-info-route {
				display: flex;
				padding: 15px;
				& > ul {
					flex: 1;
					li {
						padding: 6px 0;
					}
				}
			}
		}
	}
	.car-info-line {
		width: 393px;
		height: 150px;
		background: url("../images/line.png") no-repeat 0 0 / 100% 100% transparent;
	}
	.car-info-title {
		width: 277px;
		height: 31px;
		font-size: 14px;
		font-weight: bold;
		background: url("../images/title.png") no-repeat 0 0 / 100% 100% transparent;
		display: flex;
		align-items: center;
		padding-left: 28px;
		padding-right: 10px;
		.close-icon {
			margin-left: auto;
			cursor: pointer;
		}
	}
}
</style>
