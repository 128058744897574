<template>
	<div class="line-sta-container">
		<div class="top-car-list">
			<span class="text">待发车</span>
			<div class="car-list">
				<div class="car-item" v-for="(item, index) in upCarList" :key="index">{{ item.vehicleNo }}</div>
			</div>
		</div>
		<div>
			<LineMove :lineInfo="lineData"></LineMove>
		</div>
		<!-- <div class="rela-time-content">
			<div class="up-list" v-if="upStationList.length">
				<div class="circle left-top"></div>
				<div class="circle right-top"></div>
				<span class="start-station"></span>
				<div class="up-item" :style="getStyle(upStationList)" v-for="(item, index) in upStationList" :key="index">
					<p class="station-name">{{ item.siteName }}</p>
					<p class="line">
						<span v-if="item.isCurrent" class="current-car"></span>
						<span v-else class="station-index">{{ index + 1 }}</span>
					</p>
					<template v-if="item.isCurrent">
						<span class="current-icon"></span>
						<span class="ve-no">{{ item.vehicleNo }}</span>
					</template>
				</div>
			</div>
			<div class="down-list" v-if="downStationList.length">
				<div class="circle left-bottom"></div>
				<div class="circle right-bottom"></div>
				<span class="start-station"></span>
				<div class="up-item" :style="getStyle(downStationList)" v-for="(item, index) in downStationList" :key="index">
					<p class="line">
						<span v-if="item.isCurrent" class="current-car"></span>
						<span v-else class="station-index">{{ index + 1 }}</span>
					</p>
					<p class="station-name down">{{ item.siteName }}</p>
					<template v-if="item.isCurrent">
						<span class="current-icon"></span>
						<span class="ve-no">{{ item.vehicleNo }}</span>
					</template>
				</div>
			</div>
		</div> -->
		<div class="bottom-car-list">
			<span class="text">待发车</span>
			<div class="car-list">
				<div class="car-item" v-for="(item, index) in upCarList" :key="index">{{ item.vehicleNo }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import LineMove from "../LineMove/index";

export default {
	components: {
		LineMove
	},
	data() {
		return {
			upCarList: [],
			dwonCarList: [],
			upStationList: [],
			downStationList: [],
			lineData: {}
		};
	},
	props: {
		lineInfo: {
			type: Object,
			default: () => ({})
		}
	},

	watch: {
		lineInfo: {
			handler(obj) {
				obj.routeId && this.init();
			},
			deep: true
		}
	},
	methods: {
		init() {
			this.clearInter();
			const url = `/crrc-palm-bus/gis/line/getRouteInfo`;
			const params = {
				routeId: this.lineInfo.routeId
			};
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				// const { downSites, planVehicles, upSites, vehicles } = res;
				const { planVehicles } = res;
				this.lineData = res || {};
				this.getPlanVe(planVehicles);
				/* this.upStationList = upSites;
				this.downStationList = downSites;
				this.formatList(vehicles); */
				this.setInter();
			});
		},
		getPlanVe(list) {
			this.upCarList = list.filter(item => item.goType == 1);
			this.dwonCarList = list.filter(item => item.goType == 2);
		},
		formatList(list) {
			this.upStationList.forEach(item => {
				const target = list.find(i => i.siteId == item.siteId);
				if (target) {
					this.$set(item, "isCurrent", true);
					this.$set(item, "vehicleNo", target.vehicleNo);
				} else {
					this.$set(item, "isCurrent", false);
				}
			});
			this.downStationList.forEach(item => {
				const target = list.find(i => i.siteId == item.siteId);
				if (target) {
					this.$set(item, "isCurrent", true);
					this.$set(item, "vehicleNo", target.vehicleNo);
				} else {
					this.$set(item, "isCurrent", false);
				}
			});
		},
		setInter() {
			this.clearInter();
			this.timer = setInterval(() => {
				this.init();
			}, 5000);
		},
		clearInter() {
			this.timer && clearInterval(this.timer);
			this.timer = null;
		},
		getStyle(list) {
			return {
				width: `${1500 / list.length}px`
			};
		}
	}
};
</script>

<style lang="scss" scoped>
.line-sta-container {
	width: 100%;
	height: 100%;
	background: linear-gradient(180deg, #07375d 0%, #00213a 100%);
	border-radius: 0 0 50px 50px;
	padding: 18px 64px 7px 18px;
	.circle {
		width: 50px;
		height: 50px;
		border-top: solid 6px #2b8a23;
		border-left: solid 6px #2b8a23;
		border-top-left-radius: 100%;
		position: absolute;
		&.left-top {
			left: -50px;
			bottom: -44px;
		}
		&.right-top {
			right: -50px;
			bottom: -44px;
			transform: rotate(90deg);
		}
		&.left-bottom {
			border-top: solid 6px #0283eb;
			border-left: solid 6px #0283eb;
			left: -50px;
			top: -44px;
			transform: rotate(270deg);
		}
		&.right-bottom {
			border-top: solid 6px #0283eb;
			border-left: solid 6px #0283eb;
			right: -50px;
			top: -44px;
			transform: rotate(180deg);
		}
	}

	.top-car-list,
	.bottom-car-list {
		position: absolute;
		display: flex;
		align-items: center;
		.text {
			font-size: 14px;
			font-weight: 600;
			color: #ffffff;
			line-height: 20px;
			margin-right: 14px;
		}
		.car-list {
			display: flex;
			align-items: center;
			.car-item {
				height: 32px;
				padding: 8px 12px;
				margin-right: 6px;
				font-size: 16px;
				font-weight: 600;
				color: #ffffff;
				line-height: 16px;
				background: url("~@/assets/bigScreen/line/vehicleNo_bg_icon.png") no-repeat;
				background-size: 100% 100%;
			}
		}
	}
	.bottom-car-list {
		flex-direction: row-reverse;
		right: 64px;
		bottom: 7px;
		.text {
			margin-right: 0;
			margin-left: 14px;
		}
	}
	.rela-time-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 20px 60px 14px 114px;
		margin-top: 32px;
		.up-list,
		.down-list {
			position: relative;
			display: flex;
			align-items: flex-end;
			.start-station {
				width: 38px;
				height: 38px;
				background: url("~@/assets/bigScreen/line/start_end_icon.png") no-repeat;
				background-size: 100% 100%;
				margin-bottom: -16px;
			}
			.up-item {
				position: relative;
				text-align: center;
				.station-name {
					font-size: 14px;
					color: #ffffff;
					line-height: 14px;
					margin-bottom: 10px;
					&.down {
						margin-top: 10px;
						margin-bottom: 0;
					}
				}
				.line {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 6px;
					background: #2b8a23;
					.station-index {
						width: 18px;
						height: 18px;
						background: #2c8a24;
						border: 2px solid #ffffff;
						border-radius: 50%;
						text-align: center;
						font-size: 12px;
						color: #fff;
					}
					.current-car {
						width: 39px;
						height: 14px;
						background: url("~@/assets/bigScreen/line/car_icon.png") no-repeat;
						background-size: 100% 100%;
						z-index: 1;
					}
				}
				.current-icon {
					position: absolute;
					width: 137px;
					height: 137px;
					background: url("~@/assets/bigScreen/line/sun_icon.png") no-repeat;
					background-size: 100% 100%;
					left: 50%;
					margin-left: -68.5px;
					bottom: 0;
					margin-bottom: -68.5px;
				}
				.ve-no {
					position: absolute;
					width: 85px;
					height: 34px;
					left: 50%;
					margin-left: -20px;
					top: -34px;
					background: url("~@/assets/bigScreen/line/ve_bg_icon.png") no-repeat;
					background-size: 100% 100%;
					font-size: 13px;
					font-weight: 800;
					color: #000000;
					line-height: 20px;
					padding-top: 2px;
					text-align: center;
				}
			}
		}
		.down-list {
			align-items: flex-start;
			flex-direction: row-reverse;
			margin-top: 86px;
			.start-station {
				margin-top: -16px;
				margin-bottom: 0;
			}
			.up-item {
				.line {
					background: #0283eb;
					.station-index {
						background: #0283eb;
					}
				}
				.current-icon {
					top: 0;
					margin-top: -68.5px;
				}
				.ve-no {
					top: -56px;
				}
			}
		}
	}
}
</style>
